import React, { useState, useEffect } from 'react';
import { uploadVideo, analyzeVideo, sliceVideo, getStatus, getResult } from './api/videoService';
import { Container, Row, Col, Form, Button, ProgressBar, Alert, Card, ListGroup, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faVideo, faCut, faCheckCircle, faTimesCircle, faInfoCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [file, setFile] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState('');
  const [analysisResult, setAnalysisResult] = useState(null);
  const [segments, setSegments] = useState([]);
  const [reels, setReels] = useState([]);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [videoType, setVideoType] = useState('default');

  useEffect(() => {
    let intervalId;
    if (videoId) {
      intervalId = setInterval(async () => {
        try {
          const currentStatus = await getStatus(videoId);
          setStatus(currentStatus);
          if (currentStatus === 'analyzed' || currentStatus === 'completed') {
            const result = await getResult(videoId);
            setAnalysisResult(result);
            if (result.segments) {
              setSegments(result.segments);
            }
            if (result.reels) {
              setReels(result.reels);
            }
          }
          if (currentStatus === 'completed' || currentStatus === 'error') {
            clearInterval(intervalId);
          }
        } catch (error) {
          console.error('Error fetching status:', error);
          setError('Ошибка при получении статуса');
          clearInterval(intervalId);
        }
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [videoId]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleVideoTypeChange = (event) => {
    setVideoType(event.target.value);
  };

  const handleUpload = async () => {
    try {
      setProgress(0);
      setError(null);
      setVideoId(null);
      setStatus('');
      setAnalysisResult(null);
      setSegments([]);
      setReels([]);
      const id = await uploadVideo(file, videoType, setProgress);
      setVideoId(id);
      setStatus('uploaded');
    } catch (error) {
      console.error('Error uploading video:', error);
      setError('Ошибка при загрузке видео');
    }
  };

  const handleAnalyze = async () => {
    try {
      setError(null);
      await analyzeVideo(videoId);
      setStatus('processing');
    } catch (error) {
      console.error('Error analyzing video:', error);
      setError('Ошибка при анализе видео');
    }
  };

  const handleSlice = async () => {
    try {
      setError(null);
      await sliceVideo(videoId);
      setStatus('slicing');
    } catch (error) {
      console.error('Error slicing video:', error);
      setError('Ошибка при нарезке видео');
    }
  };

  const handleDownload = (url, filename) => {
    const downloadUrl = `/api${url}`;
    fetch(downloadUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => setError('Ошибка при скачивании файла'));
  };

  const renderStatus = () => {
    switch (status) {
      case 'uploaded':
        return (
          <Alert variant="info" className="mt-3">
            <FontAwesomeIcon icon={faInfoCircle} /> Видео загружено. Готово к анализу.
          </Alert>
        );
      case 'processing':
        return (
          <Alert variant="warning" className="mt-3">
            <Spinner animation="border" size="sm" /> Видео обрабатывается...
          </Alert>
        );
      case 'analyzed':
        return (
          <Alert variant="success" className="mt-3">
            <FontAwesomeIcon icon={faCheckCircle} /> Анализ завершен.
          </Alert>
        );
      case 'slicing':
        return (
          <Alert variant="warning" className="mt-3">
            <Spinner animation="border" size="sm" /> Видео нарезается на рилсы...
          </Alert>
        );
      case 'completed':
        return (
          <Alert variant="success" className="mt-3">
            <FontAwesomeIcon icon={faCheckCircle} /> Обработка видео завершена.
          </Alert>
        );
      case 'error':
        return (
          <Alert variant="danger" className="mt-3">
            <FontAwesomeIcon icon={faTimesCircle} /> Произошла ошибка при обработке видео.
          </Alert>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="mt-4">
      <h1 className="text-center">Video Processing App</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="justify-content-center">
        <Col md={6}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Выберите видеофайл для загрузки</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <Form.Group controlId="formVideoType" className="mb-3">
            <Form.Label>Выберите тип видео</Form.Label>
            <Form.Control as="select" onChange={handleVideoTypeChange}>
              <option value="default">По умолчанию</option>
              <option value="tutorial">Обучающее видео</option>
              <option value="entertainment">Развлекательное видео</option>
              <option value="news">Новостное видео</option>
            </Form.Control>
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={!file || status !== ''}
          >
            <FontAwesomeIcon icon={faUpload} /> Загрузить
          </Button>
          {progress > 0 && progress < 100 && (
            <ProgressBar animated now={progress} label={`${progress}%`} className="mt-3" />
          )}
        </Col>
      </Row>

      {renderStatus()}

      {status === 'uploaded' && (
        <div className="text-center mt-4">
          <Button variant="success" onClick={handleAnalyze}>
            <FontAwesomeIcon icon={faVideo} /> Анализировать видео
          </Button>
        </div>
      )}

      {(status === 'analyzed' || status === 'completed') && analysisResult && (
        <div className="mt-4">
          <Card>
            <Card.Header>Результаты анализа</Card.Header>
            <Card.Body>
              <Card.Title>Описание видео</Card.Title>
              <Card.Text>{analysisResult.description || 'Описание недоступно'}</Card.Text>

              <Card.Title>Ключевые слова</Card.Title>
              <Card.Text>{analysisResult.keywords?.join(', ') || 'Ключевые слова не найдены'}</Card.Text>

              <Card.Title>Суммаризация</Card.Title>
              <Card.Text>{analysisResult.summary || 'Суммаризация недоступна'}</Card.Text>

              <Card.Title>Транскрипционные сегменты</Card.Title>
              <ListGroup variant="flush">
                {Array.isArray(analysisResult.transcription_segments) && analysisResult.transcription_segments.length > 0 ? (
                  analysisResult.transcription_segments.map((segment, index) => (
                    <ListGroup.Item key={index}>
                      {segment.start} - {segment.end}: {segment.text}
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>Транскрипционные сегменты не доступны</ListGroup.Item>
                )}
              </ListGroup>

              <Card.Title>Обнаруженные объекты</Card.Title>
              <ListGroup variant="flush">
                {Array.isArray(analysisResult.detected_objects) && analysisResult.detected_objects.length > 0 ? (
                  analysisResult.detected_objects.map((obj, index) => (
                    <ListGroup.Item key={index}>
                      Время: {obj.timestamp}, Объекты: {obj.objects.map(o => o.class).join(', ')}
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>Объекты не обнаружены</ListGroup.Item>
                )}
              </ListGroup>

              <Card.Title className="mt-3">Классифицированные сцены</Card.Title>
              <ListGroup variant="flush">
                {Array.isArray(analysisResult.classified_scenes) && analysisResult.classified_scenes.length > 0 ? (
                  analysisResult.classified_scenes.map((scene, index) => (
                    <ListGroup.Item key={index}>
                      Кадр: {scene.frame}, Сцена: {scene.scene}
                    </ListGroup.Item>
                  ))
                ) : (
                  <ListGroup.Item>Классифицированные сцены не доступны</ListGroup.Item>
                )}
              </ListGroup>

              {status !== 'completed' && (
                <div className="text-center mt-4">
                  <Button variant="info" onClick={handleSlice}>
                    <FontAwesomeIcon icon={faCut} /> Нарезать на рилсы
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      )}

      {status === 'completed' && reels.length > 0 && (
        <div className="mt-4">
          <Card>
            <Card.Header>Рилсы</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {reels.map((reel, index) => (
                  <ListGroup.Item key={index}>
                    <span>Рилс {index + 1}</span>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="ml-2"
                      onClick={() => handleDownload(reel, `reel_${index + 1}.mp4`)}
                    >
                      <FontAwesomeIcon icon={faDownload} /> Скачать
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      )}

      {status === 'completed' && segments.length > 0 && (
        <div className="mt-4">
          <Card>
            <Card.Header>Сегменты видео</Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                {segments.map((segment, index) => (
                  <ListGroup.Item key={index}>
                    <span>Сегмент {index + 1}</span>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="ml-2"
                      onClick={() => handleDownload(segment, `segment_${index + 1}.mp4`)}
                    >
                      <FontAwesomeIcon icon={faDownload} /> Скачать
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
      )}
    </Container>
  );
}

export default App;

