import axios from 'axios';
import { logInfo, logError } from '../utils/logger';

const API_BASE_URL = 'https://tuc.co.il/api/';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  timeout: 3000000, // 50 минут
});

export const uploadVideo = async (file, videoType, setProgress) => {
  logInfo('Starting video upload');
  const formData = new FormData();
  formData.append('file', file);
  formData.append('video_type', videoType);

  try {
    const response = await axiosInstance.post('upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (typeof setProgress === 'function') {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      },
    });
    logInfo('Video uploaded successfully');
    return response.data.video_id;
  } catch (error) {
    logError('Error uploading video:', error);
    throw error;
  }
};

export const analyzeVideo = async (videoId) => {
  logInfo(`Starting analysis for video: ${videoId}`);
  try {
    const response = await axiosInstance.post(`analyze/${videoId}`);
    logInfo('Analysis initiated successfully');
    return response.data;
  } catch (error) {
    logError('Error analyzing video:', error);
    throw error;
  }
};

export const sliceVideo = async (videoId) => {
  logInfo(`Starting slicing for video: ${videoId}`);
  try {
    const response = await axiosInstance.post(`slice/${videoId}`);
    logInfo('Video slicing initiated successfully');
    return response.data;
  } catch (error) {
    logError('Error slicing video:', error);
    throw error;
  }
};

export const getStatus = async (videoId) => {
  logInfo(`Getting status for video: ${videoId}`);
  try {
    const response = await axiosInstance.get(`status/${videoId}`);
    logInfo(`Status for video ${videoId}:`, response.data.status);
    return response.data.status;
  } catch (error) {
    logError('Error getting status:', error);
    throw error;
  }
};

export const getResult = async (videoId) => {
  logInfo(`Getting result for video: ${videoId}`);
  try {
    const response = await axiosInstance.get(`result/${videoId}`);
    logInfo('Result retrieved successfully');
    return response.data;
  } catch (error) {
    logError('Error getting result:', error);
    throw error;
  }
};
